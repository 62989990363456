

























import { Component, Vue, Prop } from 'vue-property-decorator';

/**
 * CMS Image component
 *
 * @author Kevin <v-imeDanne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSComponentImagee extends Vue {
    @Prop({ type: String, required: true }) readonly src!: string;
    @Prop({ type: String, default: "undefined" }) readonly maxWidth!: string;
    @Prop({ type: String, default: "undefined" }) readonly maxHeight!: string;
    @Prop({ type: String, default: "undefined" }) readonly width!: string;
    @Prop({ type: String, default: "undefined" }) readonly height!: string;
}
